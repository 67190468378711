import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { FaStar} from 'react-icons/fa';


export default class Sidebar extends React.Component {

  render() {

	    const starRating = get(this, 'props.stars');


		if (starRating === 'One') {
			return(
				<span className="rating"><StyledStar /> <StyledStarLight /> <StyledStarLight /> <StyledStarLight /> <StyledStarLight /> </span>
			);
		}

		if (starRating === 'Two') {
			return(
				<span className="rating"><StyledStar /> <StyledStar /> <StyledStarLight /> <StyledStarLight /> <StyledStarLight /></span>
			);
		}

		if (starRating === 'Three') {
			return(
				<span className="rating"><StyledStar /> <StyledStar /> <StyledStar /> <StyledStarLight /> <StyledStarLight /></span>
			);
		}

		if (starRating === 'Four') {
			return(
				<span className="rating"><StyledStar /> <StyledStar /> <StyledStar /> <StyledStar /> <StyledStarLight /></span>
			);
		}

		if (starRating === 'Five') {
			return(
				<span className="rating"><StyledStar /> <StyledStar /> <StyledStar /> <StyledStar /> <StyledStar /></span>
			);
		}


		return(
			<span className="rating"><p>No rating</p></span>
		);

	}
}

const StyledStar = styled(FaStar)`
	color: #ff9900;	
`

const StyledStarLight = styled(FaStar)`
	color: #ffd390;	
`